<!-- 顾问匹配申请 -->
<template>
  <div>
  <div class="bg-white">
      <div class="formBox">
        <el-form :model="formData" :rules="rules" ref="formData" label-width="150px" class="demo-ruleForm">
          <el-row class="paddingleft"  style="padding-bottom:20px">
            <div class="bigTit">匹配申请</div>
            <el-col :span="8">
                <el-form-item label="姓名：" prop="name">
                    <el-input type="text" v-model="formData.name"  placeholder="输入姓名"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="性别：" prop="sex">
                    <el-radio-group v-model="formData.sex">
                    <el-radio label="男"></el-radio>
                    <el-radio label="女"></el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="年龄：" prop="age">
                    <el-input type="text" v-model="formData.age"  placeholder="输入年龄"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="目前就读年级：" prop="grade">
                    <el-input type="text" v-model="formData.grade"  placeholder="输入目前就读年级"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="学校名称：" prop="schoolName">
                    <el-input type="text" v-model="formData.schoolName"  placeholder="输入学校名称"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="学校地址：" prop="schoolAddress">
                    <el-input type="text" v-model="formData.schoolAddress"  placeholder="输入学校地址"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="8">
                <el-form-item label="学校类型：" prop="schoolType">
                    <el-input type="text" v-model="formData.schoolType"  placeholder="输入学校类型"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="入住时间：" prop="TostayinTime">
                    <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择入住时间" v-model="formData.TostayinTime"></el-date-picker>
                </el-form-item>
            </el-col>
            <el-col :span="8">
                <el-form-item label="入住时长：" prop="TostayinThelength">
                    <el-input type="text" v-model="formData.TostayinThelength"  placeholder="输入入住时长"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="24">
                <el-form-item label="期望找什么样的住家：" prop="introduction">
                    <el-input type="textarea" v-model="formData.introduction" :autosize="{minRows:4}" placeholder="输入期望找什么样的住家"></el-input>
                </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="btnBox">
        <el-button round type="primary" @click="submitForm('formData')">提交申请</el-button>
    </div>
</div>
</template>

<script>
import { matchingAdd } from '@/api/matchingform'
export default {
  data() {
    return {
        formData: {
            name: '',// 姓名
            sex: '',// 性别
            age: '',// 年龄
            grade: '', // 年级
            schoolName: '',// 学校名称
            schoolAddress:'',// 学校地址
            schoolType:'',// 学校类型
            TostayinTime:'',// 入住时间
            TostayinThelength:'',// 入住时长
            introduction:'',// 期望住家
        },
        rules: {
            name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        },
    }
  },
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            var { 
              name,sex,age,grade,schoolName,schoolAddress,schoolType,TostayinTime,TostayinThelength,introduction
            } = this.formData
          matchingAdd({
            school: schoolName,
            rzsc: TostayinThelength,
            sex: sex,
            username: name,
            age: age,
            grade: grade,
            record: this.$cookie.get('name'),// 记录人
            address: schoolAddress,
            expect: introduction,
            rzsj: TostayinTime,
            schooltype: schoolType,
          }).then((res)=>{
            if(res.code == 200){
                this.$refs[formName].resetFields();
                this.$message({
                    message: '提交成功！',
                    type: 'success'
                });
            }
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
}
</script>
<style lang='scss' scoped>
/* @import url(); 引入css类 */
.bg-white{
  background: #ffffff;
}
.bigTit{
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1E1E1E;
    border-bottom: 1px solid #EFEFEF;
    padding: 0px 0 22px 0;
    margin-bottom: 45px;
}
.formBox{
    padding-top:35px;
}
.demo-ruleForm{
    .paddingleft{
        padding-left: 45px;
    }
    .padding45{
        padding: 45px 0 20px 45px;
    }
    .padding45-2{
        padding: 45px 0 0 45px;
    }
    .rowwhitetopbor{
        padding-left: 45px;
        border-top: 20px solid #FAFAFA;
        padding-top:45px;
    }
    .rowwhitebombor{
        padding-left: 45px;
        border-bottom: 20px solid #FAFAFA;
        padding-bottom: 20px;
    }
}
::v-deep .el-input{
  width: 240px;
}
::v-deep .el-textarea__inner{
    background: #F8F8F8;
    width: 98%;
}
.btnBox{
    text-align: center;
    padding: 50px 0 40px 0;
}
</style>